import React from 'react'
import { Avatar } from '@material-ui/core'
import DoneTwoToneIcon from '@material-ui/icons/DoneTwoTone'
import useStyles from './styles'

const ConfirmationAvatar = () => {
  const classes = useStyles()

  return (
    <Avatar className={classes.avatar} src="">
      <DoneTwoToneIcon />
    </Avatar>
  )
}
export default ConfirmationAvatar
