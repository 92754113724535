import React, { forwardRef, useRef } from 'react'
import { Button } from 'components/CustomButtons'
import { InputLabel } from '@material-ui/core'
import { updateBookingField } from 'actions/booking'
import { statusConfirmed, statusCancelled } from 'constants/bookings'
import { useSnackbar } from 'notistack'
import { Link } from 'gatsby'
import ConfirmDialog from 'components/ConfirmDialog'

const BookingStatusEditor = forwardRef(({ label, booking, afterEdited, afterStatusChanged }, ref) => {
  const confirmDialogRef = useRef()
  const cancelDialogRef = useRef()
  const { enqueueSnackbar } = useSnackbar()

  const updateBookingStatus = async (status) => {
    try {
      await updateBookingField(booking.id, booking, { status })
      enqueueSnackbar('Updated Successfully!', {
        variant: 'success',
      })
      afterEdited(status)
      afterStatusChanged(status)
    } catch (err) {
      enqueueSnackbar(err.message, {
        variant: 'error',
      })
    }
  }
  const onConfirm = () => confirmDialogRef.current.open()
  const onConfirmBooking = async () => updateBookingStatus(statusConfirmed.value)

  const onCancel = () => cancelDialogRef.current.open()
  const onCancelBooking = () => updateBookingStatus(statusCancelled.value)

  const onClose = () => afterEdited()

  return (
    <>
      <div style={{ margin: '20px 0' }}>
        <InputLabel style={{ marginBottom: 10 }}>{label}</InputLabel>
        {booking.needsPayment ? (
          <Button color="primary" component={Link} to={`/booking/${booking.id}/pay`}>
            Pay Booking
          </Button>
        ) : (
          <Button color="primary" onClick={onConfirm}>
            Confirm Booking
          </Button>
        )}
        <Button color="secondary" onClick={onCancel}>
          Cancel Booking
        </Button>
        <div style={{ marginTop: 10 }}>
          <Button onClick={onClose}>Close</Button>
        </div>
        {!booking.needsPayment && (
          <ConfirmDialog
            ref={confirmDialogRef}
            onConfirm={onConfirmBooking}
            title="Confirm Booking?"
            text="Are you sure to confirm the booking?"
          />
        )}
        <ConfirmDialog
          ref={cancelDialogRef}
          onConfirm={onCancelBooking}
          title="Cancel Booking?"
          text="Are you sure to cancel the booking?"
        />
      </div>
    </>
  )
})

export default BookingStatusEditor
