import React, { forwardRef } from 'react'
import { CardHeader, CardContent, CardActions } from '@material-ui/core'
import HomeTwoToneIcon from '@material-ui/icons/HomeTwoTone'
import Button from 'components/CustomButtons/Button'
import QRCode from 'react-qr-code'
import Item from 'components/BookingItem'
import useStyles from './styles'
import Avatar from './avatar'
import { Link } from 'gatsby'
import { bookingStatuses } from 'constants/bookings'

const Confirmation = forwardRef(({ booking, action, search, approx }, ref) => {
  const classes = useStyles()

  return (
    <div ref={ref}>
      <CardHeader
        className={classes.cardHeader}
        title="Success"
        titleTypographyProps={{ className: classes.cardHeaderTitle }}
        action={action}
        avatar={<Avatar />}
      />
      <CardContent className={classes.cardContent}>
        <div className={classes.qrContainer}>
          {booking && <QRCode value={booking.id} className={classes.qr} size={128} />}
        </div>
        <Item label="Time" text={booking?.displayTime(false, approx)} />
        <Item label="Status" text={bookingStatuses.find((s) => s.value === booking?.status)?.label} />
        <Item label="Name" text={booking?.name} />
        <Item label="Phone" text={booking?.phone} />
        <Item label="Email" text={booking?.email} />
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Button component={Link} to={`/${search}`} fullWidth>
          <HomeTwoToneIcon fontSize="large" />
          Go home
        </Button>
      </CardActions>
    </div>
  )
})

export default Confirmation
