import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import CustomInput from 'components/CustomInput'
import { LoadingButton, Button } from 'components/CustomButtons'
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import { updateBookingField } from 'actions/booking'
import { useSnackbar } from 'notistack'
import getProps from 'utils/getProps'

const EditForm = ({
  booking,
  name,
  afterEdited,
  component: Component = CustomInput,
  defaultValue,
  onSave,
  ...props
}) => {
  const {
    control,
    handleSubmit,
    formState: { isDirty, isSubmitting },
  } = useForm({ defaultValues: booking })
  const { enqueueSnackbar } = useSnackbar()

  const onUpdate = async (data) => {
    if (onSave) {
      return onSave(data, afterEdited)
    }
    try {
      await updateBookingField(booking.id, booking, data)
      enqueueSnackbar('Updated Successfully!', {
        variant: 'success',
      })
      afterEdited(getProps(data, name))
    } catch (err) {
      enqueueSnackbar(err.message, {
        variant: 'error',
      })
    }
  }

  const onCancel = () => {
    afterEdited()
  }

  return (
    <form onSubmit={handleSubmit(onUpdate)}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Controller control={control} name={name} render={({ field }) => <Component {...field} {...props} />} />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <LoadingButton
            variant="contained"
            color="primary"
            type="submit"
            disabled={!isDirty || isSubmitting}
            isLoading={isSubmitting}
          >
            Save
          </LoadingButton>
          <Button onClick={onCancel}>Close</Button>
        </GridItem>
      </GridContainer>
    </form>
  )
}

export default EditForm
