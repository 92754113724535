import React, { useEffect, useState } from 'react'
import { CardContent, CardActions, FormHelperText } from '@material-ui/core'
import LoadingButton from 'components/CustomButtons/LoadingButton'
import useStyles from './styles'
import { useLoading } from 'hooks/useLoading'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import CustomInput from 'components/CustomInput'
import CustomPhoneInput from 'components/CustomPhoneInput'
import CustomSelect from 'components/CustomSelect'
import OtpModel from 'models/Otp'
import { requestOtp } from 'actions/otp'
import { guestLogin } from 'actions/login'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { validateRecaptcha } from 'actions/recaptcha'

const loginOptions = [
  { label: 'Email', value: 'email' },
  { label: 'Phone', value: 'phone' },
]

const BookingLoginForm = ({ onSubmitForm, bookingId = '' }) => {
  const classes = useStyles()
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(OtpModel.validationSchema),
    defaultValues: { loginType: 'phone', phone: '', email: '', otp: '', bookingId },
  })

  const watchLoginType = watch('loginType')
  const [, setLoading] = useLoading()
  const [error, setError] = useState(null)
  const { executeRecaptcha } = useGoogleReCaptcha()

  useEffect(() => {
    setLoading(false)
  }, [])

  const submitForm = async (data) => {
    try {
      setError(null)
      const token = await executeRecaptcha('loginBooking')
      const { user } = await guestLogin()

      await validateRecaptcha(token, await user.getIdToken())
      await user.getIdToken(true)

      return requestOtp({ userId: user.uid, ...data }).then(async (d) => onSubmitForm(d))
    } catch (e) {
      setError('Error while trying to login!')
    }
  }

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <CardContent className={classes.cardContent}>
        <h2 className={classes.title}>Sign in</h2>
        <Controller
          control={control}
          name="loginType"
          render={({ field }) => (
            <CustomSelect {...field} label="Login By" options={loginOptions} error={errors.type} fullWidth />
          )}
        />
        {watchLoginType === 'email' ? (
          <Controller
            control={control}
            name="email"
            render={({ field }) => (
              <CustomInput {...field} type="email" label="Email Address" error={errors.email} fullWidth autoFocus />
            )}
          />
        ) : (
          <Controller
            control={control}
            name="phone"
            render={({ field }) => (
              <CustomPhoneInput {...field} label="Phone" error={errors.phone} fullWidth autoFocus />
            )}
          />
        )}
        <Controller
          control={control}
          name="bookingId"
          render={({ field }) => <CustomInput {...field} label="Booking Number" error={errors.bookingId} fullWidth />}
        />
        {error && <FormHelperText error>{error}</FormHelperText>}
      </CardContent>
      <CardActions className={classes.cardActions}>
        <LoadingButton type="submit" variant="contained" isLoading={isSubmitting} color="primary" size="large">
          Login
        </LoadingButton>
      </CardActions>
    </form>
  )
}

export default BookingLoginForm
