import Otp from './index'
import timestamp from 'utils/firebaseTimestamp'

const OtpConverter = {
  toFirestore: (otp) => {
    const { id, ...fs } = otp
    return {
      ...fs,
      ...timestamp(id),
    }
  },
  fromFirestore: (snapshot, options) => {
    const otp = snapshot.data(options)
    return new Otp(
      otp.userId,
      otp.bookingId,
      otp.loginType,
      otp.email,
      otp.phone,
      otp.otpCode,
      otp.expiredIn?.toDate(),
      otp.verifiedAt?.toDate(),
      snapshot.id
    )
  },
}
export default OtpConverter
