import yup from 'utils/yup'

const validationSchema = (code) =>
  yup.object({
    otpCode: yup
      .string()
      .label('OTP Code')
      .required()
      .length(6)
      .test('otp', 'OTP Code is invalid!', (value) => code === value),
  })

export default validationSchema
