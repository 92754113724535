import { BOOKING_EDITABLE } from 'config/app'
import LoginBooking from 'components/Public/Booking/Login'
import EditBooking from 'components/Public/Booking/Edit'
import PaymentBooking from 'components/Public/Booking/Payment'
import CancelPayment from 'components/Public/Booking/Payment/Cancel'
import BookingConfirmation from 'components/Public/Booking/Confirmation'

export const bookingSuccessUrl = ({ id }) => `/booking/${id}`

const bookingEditableRoutes = [
  { path: '/booking', component: LoginBooking, title: 'Booking' },
  { path: '/booking/:bookingId', component: LoginBooking, title: 'Booking' },
  { path: '/booking/:bookingId/edit', component: EditBooking, title: 'Edit Booking', secured: true },
]

const bookingRoutes = [
  {
    path: '/booking/:bookingId/confirmation',
    component: BookingConfirmation,
    title: 'Booking Confirmation',
  },
  { path: '/booking/:bookingId/pay', component: PaymentBooking, title: 'Payment' },
  { path: '/booking/:bookingId/pay/cancel', component: CancelPayment, title: 'Cancel Payment' },
]

const routes = BOOKING_EDITABLE ? bookingRoutes.concat(bookingEditableRoutes) : bookingRoutes
export default routes
