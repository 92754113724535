import React, { useState } from 'react'
import Display from './display'

const Item = ({ label, text, value = text, editable, renderEdit: EditComponent = () => <></> }) => {
  const [edit, setEdit] = useState(false)
  const [editedText, setEditedText] = useState()

  const onEdit = () => {
    setEdit(true)
  }

  const getDisplayText = (txt) => (typeof text === 'function' ? text(txt) : txt)

  const afterEdited = (txt) => {
    if (txt) {
      setEditedText(getDisplayText(txt))
    }
    setEdit(false)
  }

  return (
    <>
      {text && (
        <>
          {edit ? (
            <EditComponent afterEdited={afterEdited} label={label} />
          ) : (
            <Display label={label} text={editedText ?? getDisplayText(value)} editable={editable} onEdit={onEdit} />
          )}
        </>
      )}
    </>
  )
}

export default Item
