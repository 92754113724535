import createOtp from './createOtp'
import updateOtp from './updateOtp'
import getCollection from './getCollection'
import { where, query, limit, getDocs } from 'firebase/firestore/lite'

const getUnExpired = async (otp) => {
  let q = query(
    getCollection(),
    where('bookingId', '==', otp.bookingId),
    where('expiredAt', '>=', new Date()),
    where('verifiedAt', '==', null),
    limit(1)
  )

  if (otp.loginType === 'phone') {
    q = query(q, where('phone', '==', otp.phone))
  } else {
    q = query(q, where('email', '==', otp.email))
  }

  return getDocs(q)
}

const requestOtp = async (otp) => {
  const d = await getUnExpired(otp)

  if (!d.empty) {
    const doc = d.docs.pop()
    updateOtp(doc.id, { userId: otp.userId })
    return doc.data()
  }
  return createOtp(otp).then(async (docRef) => docRef.get().then(async (d) => d.data()))
}

export default requestOtp
