import React, { forwardRef } from 'react'
import { Dialog, DialogContent, DialogActions, Button } from '@material-ui/core'
import useOpenClose from 'hooks/useOpenClose'

export default forwardRef(function CustomAlert({ message, buttonText = 'OK', onClose }, ref) {
  const { open } = useOpenClose(ref)
  return (
    <Dialog onClose={onClose} open={open} style={{ minHeight: 200, minWidth: 300 }}>
      <DialogContent style={{ padding: '30px 40px', fontSize: 15 }}>{message}</DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose} fullWidth={false} autoFocus>
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  )
})
