import React, { useEffect, useRef } from "react";

export default ({ form: { postUrl, fields } }) => {
  const form = useRef();

  useEffect(() => {
    form.current.submit();
  }, [form]);

  return (
    <form action={postUrl} method="post" ref={form}>
      {fields.map((field, key) => (
        <input key={key} type="hidden" name={field.name} value={field.value} />
      ))}
    </form>
  );
};
