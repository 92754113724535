import React from 'react'
import { Typography } from '@material-ui/core'
import { Button } from 'components/CustomButtons'
import useStyles from './styles'

const Display = ({ label, text, editable, onEdit }) => {
  const classes = useStyles()

  return (
    <>
      <Typography className={classes.title} color="textSecondary" gutterBottom>
        {label}
      </Typography>
      <Typography variant="h6" component="div">
        <span className={classes.text}>{typeof text === 'function' ? text() : text}</span>
        {editable && (
          <Button className={classes.button} variant="contained" size="small" onClick={onEdit}>
            Edit
          </Button>
        )}
      </Typography>
    </>
  )
}

export default Display
