import React, { memo, useEffect } from 'react'
import useApi from 'hooks/useApi'
import { statusConfirmed } from 'constants/bookings'
import Gateways from './gateways'
import { getBooking } from 'actions/booking'
import { navigate } from 'gatsby'
import { Typography } from '@material-ui/core'

const PaymentBooking = memo(({ bookingId, location: { search }, ...props }) => {
  const [booking] = useApi(() => Promise.all([getBooking(bookingId)]))

  useEffect(() => {
    if (booking && (booking.status === statusConfirmed.value || !booking.fee)) {
      navigate(`/booking/${bookingId}/confirmation${search ?? ''}`)
    }
  }, [booking])

  return (
    <>
      {booking?.status === statusConfirmed.value || !booking?.fee ? (
        <></>
      ) : booking?.deletedAt ? (
        <Typography variant="h6" style={{ padding: 20 }}>
          Expired or Invalid Booking
        </Typography>
      ) : (
        <Gateways
          bookingId={bookingId}
          search={search}
          {...props}
        />
      )}
    </>
  )
})

export default PaymentBooking
