import React from 'react'
import { Router } from '@gatsbyjs/reach-router'
import Layout from 'components/Layout/Booking'
import BookingSecuredRoute from 'components/BookingSecuredRoute'
import { AuthProvider } from 'hooks/usePublicAuth'
import { LoadingProvider } from 'hooks/useLoading'
import routes from 'routes/booking'

function LayoutedComponent(Component, title) {
  return (props) => (
    <Layout title={title} {...props}>
      <Component {...props} />
    </Layout>
  )
}
export default function BookingPage({ location }) {
  return (
    <AuthProvider search={location.search}>
      <LoadingProvider>
        <Router>
          {routes.map(({ path, component, title, ...props }) => (
            <BookingSecuredRoute
              key={path}
              path={path}
              component={LayoutedComponent(component, title)}
              location={location}
              {...props}
            />
          ))}
        </Router>
      </LoadingProvider>
    </AuthProvider>
  )
}
