import React, { forwardRef } from 'react'
import { Dialog, DialogContent } from '@material-ui/core'
import useOpenClose from 'hooks/useOpenClose'

export default forwardRef(function MessageDialog({ message }, ref) {
  const { open } = useOpenClose(ref)
  return (
    <Dialog ref={ref} open={open}>
      <DialogContent dangerouslySetInnerHTML={{ __html: message }} />
    </Dialog>
  )
})
