import React, { useEffect, useState } from 'react'
import { LoadingButton } from 'components/CustomButtons'
import { differenceInSeconds } from 'date-fns'

const Counter = ({ expiredAt, onRequestOtpAgain, isSubmitting }) => {
  const [remaining, setRemaining] = useState(differenceInSeconds(expiredAt, new Date()))
  const minute = parseInt(remaining / 60)
  const second = remaining % 60

  useEffect(() => {
    let timer
    if (expiredAt) {
      timer = setInterval(() => {
        const r = differenceInSeconds(expiredAt, new Date())
        if (r === 0) {
          clearInterval(timer)
        }
        setRemaining(r)
      }, 1000)
    }

    return () => {
      if (timer) {
        clearInterval(timer)
      }
    }
  }, [expiredAt])

  return (
    <>
      <div style={{ marginRight: 10 }}>
        {remaining > 0 ? (
          !isNaN(remaining) && `${minute}:${second < 10 ? `0${second}` : second}`
        ) : (
          <LoadingButton size="small" onClick={onRequestOtpAgain}>
            Not Recevied? Request Again.
          </LoadingButton>
        )}
      </div>
      <LoadingButton
        type="submit"
        variant="contained"
        color="primary"
        isLoading={isSubmitting}
        disabled={remaining <= 0}
      >
        Verify
      </LoadingButton>
    </>
  )
}

export default Counter
