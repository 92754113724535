import yup from 'utils/yup'
import BaseModel from 'models/BaseModel'
import { addSeconds } from 'date-fns'
import { OTP_EXPIRED_SECONDS } from 'config/app'
import otp from 'utils/otp'

class OtpModel extends BaseModel {
  constructor(
    userId = '',
    bookingId = '',
    loginType = '',
    email = '',
    phone = '',
    otpCode = otp(),
    expiredAt = addSeconds(new Date(), OTP_EXPIRED_SECONDS),
    verifiedAt = null,
    id = null
  ) {
    super()
    console.log(otpCode)
    this.userId = userId
    this.bookingId = bookingId
    this.loginType = loginType
    this.email = email
    this.phone = phone
    this.otpCode = otpCode
    this.expiredAt = expiredAt
    this.verifiedAt = verifiedAt
    this.id = id
  }

  refresh() {
    this.otpCode = otp()
    this.expiredAt = addSeconds(new Date(), OTP_EXPIRED_SECONDS)
  }

  static validationSchema = yup.object({
    bookingId: yup.string().label('Booking Number').required(),
    email: yup
      .string()
      .label('Email')
      .when(['loginType'], {
        is: (loginType) => loginType === 'email',
        then: yup.string().email().required(),
      }),
    phone: yup
      .string()
      .label('Phone')
      .when(['loginType'], {
        is: (loginType) => loginType === 'phone',
        then: yup.string().required().phone(),
      }),
  })
}
export default OtpModel
