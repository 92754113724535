import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(0, 2),
  },
  card: {
    maxWidth: '50vw',
    margin: theme.spacing(4, 'auto'),
    boxShadow: theme.shadows[15],
    [theme.breakpoints.down('md')]: {
      maxWidth: '70vw',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100vw',
    },
  },
}))
