import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 0),
    '&:last-child': {
      marginBottom: 0,
    },
  },
  avatar: {
    width: '100%',
    height: 'auto',
    backgroundColor: 'transparent',
  },
  action: {
    marginTop: theme.spacing(2),
  },
}))
