import React, { useState, memo } from 'react'
import BookingLoginForm from './form'
import OtpDialog from './dialog'
import Recaptcha from 'components/Recaptcha'
import { navigate } from 'gatsby'

const BookingLoginIndex = memo(({ bookingId }) => {
  const [otpData, setOtpData] = useState({ open: false, data: {} })

  const onSubmitLoginForm = (data) => {
    setOtpData({ open: true, data })
  }

  const afterVerifiedOtp = async (data) => {
    navigate(`/booking/${bookingId}/edit`)
  }

  return (
    <>
      <Recaptcha>
        <BookingLoginForm onSubmitForm={onSubmitLoginForm} bookingId={bookingId} />
      </Recaptcha>
      <OtpDialog {...otpData} afterVerifiedOtp={afterVerifiedOtp} />
    </>
  )
})

export default BookingLoginIndex
