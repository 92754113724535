import React, { memo, useState, useEffect } from 'react'
import { Dialog, DialogContent, DialogContentText, DialogActions, DialogTitle } from '@material-ui/core'
import CustomInput from 'components/CustomInput'
import { Controller, useForm } from 'react-hook-form'
import Counter from './counter'
import validationSchema from './validation'
import { yupResolver } from '@hookform/resolvers/yup'
import { verifyOtp, regenOtp } from 'actions/otp'
import { getCurrentUser } from 'actions/login'
import { useSnackbar } from 'notistack'

const OtpDialog = memo(({ open, data, afterVerifiedOtp }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [otpData, setOtpData] = useState({ expiredAt: data.expiredAt, otpCode: data.otpCode })
  const [loading, setLoading] = useState(false)
  const currentUser = getCurrentUser()
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema(otpData.otpCode)),
    defaultValues: { otpCode: '' },
  })

  useEffect(() => {
    setOtpData({ expiredAt: data.expiredAt, otpCode: data.otpCode })
  }, [data])

  const onVerify = async ({ otpCode }) => {
    try {
      setLoading(true)
      await verifyOtp(otpCode, data, await currentUser.getIdToken(true))
      await currentUser.getIdToken(true)
      return setTimeout(() => afterVerifiedOtp(data), 1000)
    } catch (err) {
      enqueueSnackbar(err.response?.data?.message ?? err.message, { variant: 'error' })
    }
  }

  const onRequestOtpAgain = async () => {
    const newOtp = await regenOtp(data)
    setOtpData({ expiredAt: newOtp.expiredAt, otpCode: newOtp.otpCode })
  }

  return (
    <Dialog open={open} aria-labelledby="form-dialog-title">
      <form onSubmit={handleSubmit(onVerify)}>
        <DialogTitle id="form-dialog-title">Verification Code</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please type the verification code sent to {data.loginType === 'email' ? data.email : data.phone}
          </DialogContentText>
          <Controller
            control={control}
            name="otpCode"
            render={({ field }) => (
              <CustomInput
                {...field}
                type="number"
                label="Enter OTP"
                error={errors.otpCode}
                maxLength={6}
                fullWidth
                autoFocus
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Counter expiredAt={otpData.expiredAt} onRequestOtpAgain={onRequestOtpAgain} isSubmitting={loading} />
        </DialogActions>
      </form>
    </Dialog>
  )
})

export default OtpDialog
