import React, { memo, forwardRef, useState, useEffect } from 'react'
import { CardContent, CardActions, InputLabel } from '@material-ui/core'
import useStyles from './styles'
import useApi from 'hooks/useApi'
import { changeBooking, getBooking } from 'actions/booking'
import { getSettings } from 'actions/setting'
import logout from 'actions/login/logout'
import { fields } from 'utils/fields'
import getProps from 'utils/getProps'
import Item from 'components/BookingItem'
import EditForm from './editForm'
import { LoadingProvider } from 'hooks/useLoading'
import { Button } from 'components/CustomButtons'
import SlotSelector from 'components/SlotSelector'
import { navigate } from 'gatsby'
import { bookingStatuses, publicEditableBookingStatuses } from 'constants/bookings'
import BookingStatusEditor from './bookingStatusEditor'
import { differenceInDays, subDays } from 'date-fns'

const EditBookingPage = memo(({ bookingId }) => {
  const classes = useStyles()
  const [booking, settings] = useApi(() => Promise.all([getBooking(bookingId), getSettings()]))
  const [editable, setEditable] = useState(false)

  const onUpdateSlotTimetable = async ({ slotTimetable }, afterEdited) => {
    const b = await changeBooking(booking, slotTimetable)
    afterEdited(b.displayTime())
  }

  const SlotSelectorContainer = forwardRef((props, ref) => (
    <div style={{ margin: '20px 0' }}>
      <SlotSelector ref={ref} {...props} slotId={booking?.slot?.id} />
    </div>
  ))

  const onLogout = () => {
    logout().then(() => navigate(`/booking/${bookingId}`))
  }

  const getBookingStatus = (status) => bookingStatuses.find((s) => s.value === status)?.label

  useEffect(() => {
    if (settings?.editableDays && booking) {
      if (
        publicEditableBookingStatuses.find((s) => s.value === booking.status) &&
        differenceInDays(booking.start, subDays(new Date(), settings.editableDays)) > 0
      ) {
        setEditable(true)
      }
    }
  }, [settings?.editableDays, booking?.status])

  const afterStatusChanged = (status) => {
    if (!publicEditableBookingStatuses.find((s) => s.value === status)) {
      setEditable(false)
    }
  }

  return (
    <>
      <CardContent className={classes.cardContent}>
        <Item label="Booking ID" text={booking?.id} />
        <Item
          label="Booking Status"
          text={getBookingStatus}
          value={booking?.status}
          renderEdit={(props) => (
            <BookingStatusEditor booking={booking} {...props} afterStatusChanged={afterStatusChanged} />
          )}
          editable={editable}
        />
        <Item
          label="Time"
          text={booking?.displayTime()}
          renderEdit={({ label, ...props }) => (
            <LoadingProvider>
              <InputLabel style={{ marginTop: 20 }}>{label}</InputLabel>
              <EditForm
                {...props}
                name="slotTimetable"
                booking={booking}
                settings={settings}
                component={SlotSelectorContainer}
                defaultDate={booking?.start}
                onSave={onUpdateSlotTimetable}
              />
            </LoadingProvider>
          )}
          editable={editable}
        />
        <Item
          label="Name"
          text={booking?.name}
          renderEdit={(props) => <EditForm {...props} name="name" booking={booking} selectAll autoFocus fullWidth />}
          editable={editable}
        />
        <Item
          label="Phone"
          text={booking?.phone}
          renderEdit={(props) => <EditForm {...props} name="phone" booking={booking} phone autoFocus fullWidth />}
          editable={editable}
        />
        <Item
          label="Email"
          text={booking?.email}
          renderEdit={(props) => (
            <EditForm {...props} name="email" booking={booking} type="email" selectAll autoFocus fullWidth />
          )}
          editable={editable}
        />
        {fields.map(({ label, type, name }) => (
          <Item
            key={name}
            label={label}
            text={getProps(booking, name)}
            renderEdit={(props) => (
              <EditForm {...props} name={name} booking={booking} type={type} selectAll autoFocus fullWidth />
            )}
            editable={editable}
          />
        ))}
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Button onClick={onLogout}>Logout</Button>
      </CardActions>
    </>
  )
})

export default EditBookingPage
