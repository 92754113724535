import React, { memo } from 'react'
import { ArrowForwardIosTwoTone as ArrowForwardIosTwoToneIcon } from '@material-ui/icons'
import Row from 'components/Public/Slot/Listing/Row'
import useStyles from './styles'
import { CircularProgress } from '@material-ui/core'

const Loading = memo(() => {
  const classes = useStyles()
  return (
    <Row
      avatarIcon={<CircularProgress />}
      avatarVariant="rounded"
      title="Loading..."
      actionIcon={<ArrowForwardIosTwoToneIcon />}
      classes={classes}
    />
  )
})

export default Loading
