import React, { useEffect, useState, useRef } from 'react'
import { CardContent, Typography } from '@material-ui/core'
import { addMinutes, intervalToDuration } from 'date-fns'
import Row from 'components/Public/Slot/Listing/Row'
import { getSettings } from 'actions/setting'
import useApi from 'hooks/useApi'
import nl2br from 'utils/nl2br'
import { navigate } from 'gatsby'
import CustomAlert from 'components/ConfirmDialog/alert'

export default function Timer({ booking, search }) {
  const [settings] = useApi(() => Promise.all([getSettings()]))
  const [expiry, setExpiry] = useState()
  const [timer, setTimer] = useState()
  const [inter, setInter] = useState()
  const alertRef = useRef()
  const searchParams = new URLSearchParams(search)

  useEffect(() => {
    if (booking?.createdAt && settings?.autoCancelMinutes) {
      setExpiry(addMinutes(booking.createdAt, settings?.autoCancelMinutes - 7))
    }
  }, [booking?.createdAt, settings?.autoCancelMinutes])

  useEffect(() => {
    if (expiry < new Date()) {
      clearInterval(inter)
      alertRef.current.open()
    }
  }, [timer])

  const onAlertClose = () => navigate(`/?${searchParams.toString()}`)

  useEffect(() => {
    if (expiry) {
      setTimer(intervalToDuration({ start: new Date(), end: expiry }))
      setInter(setInterval(() => setTimer(intervalToDuration({ start: new Date(), end: expiry }))))
      return () => clearInterval(inter)
    }
  }, [expiry])
  return (
    <Row showHeader={false}>
      <CardContent style={{ textAlign: 'center', fontSize: 15 }}>
        <div dangerouslySetInnerHTML={{ __html: nl2br(settings?.countDownText) }} />
        <Typography component={'div'} style={{ color: 'red', fontWeight: 'bold', fontSize: 18 }}>
          {timer && `${String(timer.minutes).padStart(2, '0')}:${String(timer.seconds).padStart(2, '0')}`}
        </Typography>
      </CardContent>
      <CustomAlert ref={alertRef} message="Expired Booking!" onClose={onAlertClose} />
    </Row>
  )
}
