import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => {
  return {
    cardHeader: {
      textAlign: 'center',
      padding: theme.spacing(2.2, 4),
      backgroundColor: theme.palette.success.main,
      // boxShadow: theme.shadows[4],
    },
    cardHeaderTitle: {
      color: theme.palette.common.white,
      fontSize: 30,
      marginLeft: '-60px',
    },
    cardContent: {
      padding: theme.spacing(3, 5),
    },
    cardActions: {
      padding: theme.spacing(0, 5, 3),
      '&>button': {
        fontSize: 18,
      },
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    qrContainer: {
      textAlign: 'center',
      paddingBottom: 15,
    },
    pos: {
      marginBottom: 12,
    },
    avatar: {
      // boxShadow: theme.shadows[1],
      backgroundColor: [theme.palette.success.dark, '!important'],
    },
  }
})
