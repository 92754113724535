import React, { memo } from 'react'
import Gateways from './gateways'
import { getBooking } from 'actions/booking'
import useApi from 'hooks/useApi'
import { Typography } from '@material-ui/core'

const CancelPaymentBooking = memo(({ bookingId, location: { search }, ...props }) => {
  const [booking] = useApi(() => Promise.all([getBooking(bookingId)]))

  return (
    <>
      {booking?.deletedAt ? (
        <Typography variant="h6" style={{ padding: 20 }}>
          Expired or Invalid Booking
        </Typography>
      ) : (
        <Gateways
          bookingId={bookingId}
          search={search}
          {...props}
        />
      )}
    </>
  )
})

export default CancelPaymentBooking
