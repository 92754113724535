import React, { lazy, memo, useState, Suspense } from 'react'
import Loading from './gateways/loading'

const GatewayMethod = memo(({ gateway, method, onClick, autoSelect, ...props }) => {
  const [loading, setLoading] = useState(false)
  let GatewayComp
  if (method) {
    GatewayComp = lazy(() => import(`./gateways/${gateway}/${method}`))
  } else {
    GatewayComp = lazy(() => import(`./gateways/${gateway}`))
  }

  const onSelect = (method) => {
    setLoading(true)
    onClick(gateway, typeof method === 'string' ? method : null)
  }

  // useEffect(() => {
  //   if (autoSelect) {
  //     // setTimeout(onSelect, 1000)
  //   }
  // }, [autoSelect])

  return <GatewayComp {...props} gateway={gateway} method={method} onClick={onSelect} />
})

export default function Gateway({ config: { conf }, ...props }) {
  return (
    <Suspense fallback={<Loading />}>
      {conf.methods ? (
        conf.methods.map((method) => (
          <GatewayMethod key={method} {...props} method={method.replaceAll(' ', '_').toLowerCase()} />
        ))
      ) : (
        <GatewayMethod {...props} />
      )}
    </Suspense>
  )
}
