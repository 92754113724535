import React, { useState, useEffect, memo } from 'react'
import { navigate } from 'gatsby'
import { useAuth } from 'hooks/useAuth'
import customerLogin from 'actions/login/customerLogin'
import { getBooking } from 'actions/booking'
import useApi from 'hooks/useApi'
import { useLoading } from 'hooks/useLoading'
import Empty from 'components/Public/Empty'

const BookingSecuredRoute = memo(
  ({ component: Component, redirectNoAuth = '/booking', bookingId, secured, location, ...rest }) => {
    const [loading, auth, idToken] = useAuth()
    const [booking] = useApi(() => Promise.all([getBooking(bookingId)]))
    const [dataLoading] = useLoading()
    const query = new URLSearchParams(location.search)
    const token = query.get('token')
    const [tokenLoading, setTokenLoading] = useState(!!token)
    const [customerIdToken, setCustomerIdToken] = useState()

    useEffect(() => {
      if (token) {
        customerLogin(token).then(({ user }) =>
          user.getIdTokenResult(true).then((idTokenResult) => {
            setCustomerIdToken(idTokenResult)
            setTokenLoading(false)
          })
        )
      }
    }, [token])

    if (secured && (loading || tokenLoading || dataLoading)) {
      return <Empty />
    }

    if (
      !secured ||
      (auth &&
        (idToken?.claims.bookingId === bookingId ||
          customerIdToken?.claims.bookingId === bookingId ||
          booking?.userId === auth.uid))
    ) {
      return <Component {...rest} location={location} bookingId={bookingId} />
    }

    navigate(`${redirectNoAuth}/${bookingId}`)
    return null
  }
)

export default BookingSecuredRoute
