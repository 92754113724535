import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  title: {
    fontSize: 14,
    marginTop: theme.spacing(2),
  },
  text: {
    verticalAlign: 'middle',
  },
  button: {
    marginLeft: 10,
  },
}))
