import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  cardHeader: {
    textAlign: 'center',
    padding: theme.spacing(4),
    backgroundColor: theme.palette.success.main,
    boxShadow: theme.shadows[4],
  },
  cardHeaderTitle: {
    color: theme.palette.common.white,
    fontSize: 30,
    marginLeft: '-60px',
  },
  cardContent: {
    padding: theme.spacing(2, 5),
  },
  cardActions: {
    padding: theme.spacing(0, 5, 2),
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
    marginTop: theme.spacing(2),
    marginBottom: 0,
  },
  qrContainer: {
    textAlign: 'center',
    paddingBottom: 15,
  },
  pos: {
    marginBottom: 12,
  },
  avatar: {
    boxShadow: theme.shadows[1],
    backgroundColor: [theme.palette.success.dark, '!important'],
  },
}))
